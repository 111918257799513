<template>
  <div>
    <v-dialog v-model="show_dialog" width="500">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          NBFC Status
          <v-spacer></v-spacer>
          <v-btn fab small text @click="show_dialog = false">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <!-- {{ nbfc_status_list }} -->
          <div :style="progressTop" class="progress-wrapper">
            <v-row
              v-for="(item, index) in nbfc_status_list"
              :key="index"
              class="ma-0 pa-0"
            >
              <v-col cols="1">
                <v-icon color="success" v-if="item.status == 'ACTIVE'"
                  >mdi-check-circle-outline</v-icon
                >
                <v-icon color="warning" v-else-if="item.status == 'CURRENT'"
                  >mdi-circle-double</v-icon
                >
                <v-icon v-else-if="item.status == 'INACTIVE'"
                  >mdi-circle-outline</v-icon
                >
              </v-col>
              <v-col cols="10" class="text-body-1 font-weight-bold">
                {{ item.action }}
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      is_status_loading: false,
      nbfc_status_list: [],
      // nbfc_status_list: [
      //   { title: "Internal Score", status: "completed" },
      //   { title: "Document Verification", status: "completed" },
      //   { title: "Credit Assement Memo", status: "completed" },
      //   { title: "Video Verification", status: "in_progress" },
      //   { title: "E NACH", status: "pending" },
      //   { title: "Payment Task", status: "pending" },
      //   { title: "Disbursed", status: "pending" },
      // ],
      customer_id: "",
      progress_top: 10,
    };
  },
  computed: {
    progressTop() {
      console.log(`-${this.progress_top}%`);
      return {
        "--progress-top": `-${this.progress_top}%`,
      };
    },
  },
  methods: {
    openNbfcStatus(value) {
      // console.log(value);
      this.show_dialog = true;
      this.customer_id = value.customer_id;
      this.nbfc_status_list = value.nbfc_stages;
      // await this.getNbfcStatus();
    },
    getNbfcStatus() {
      const self = this;
      let lookup_key = this.customer_id.$urls;

      self.is_status_loading = true;
      const successHandler = (res) => {
        self.agent_list = res.data.result.user_data;
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls + `${lookup_key}/`,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.progress-wrapper {
  /* background: #ababab57; */
  padding-top: 0px !important ;
  padding-bottom: 0px !important ;
  position: relative;
  overflow: hidden;
}
/* .progress-wrapper::before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 20px;
  background-color: rgba(57, 57, 104, 0.322);
  position: absolute;
  top: 0;
  left: 21.5px;
}
.progress-wrapper::after {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 20px;
  background-color: rgb(34, 255, 0);
  position: absolute;
  top: 0;
  left: 21.5px;
  transform: scaleY(0.2); */
  /* transform: scaleY(var(--progress-top)); */
  /* transform-origin: top;
  transition: transform 500ms ease-in;
} */
</style>
